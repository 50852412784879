















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateManager } from '@/services/Managers'
import { DeviceType } from '@/types/enums'
import { AuthModule } from '@/store/modules'
import { Auth } from '@/api'

@Component
export default class SessionsSettingsCard extends Vue {
  @AuthModule.Action logout

  @Prop() device
  parseTimeDelta = DateManager.parseTimeDelta
  getDateOnlyFromDateString = DateManager.getDateOnlyFromDateString

  get clientName() {
    const clientName = this.device.clientName.split(' ')[0].toLowerCase()

    return clientName === 'unk' ? '' : clientName
  }

  get iconFromDeviceType() {
    switch (this.device.deviceType) {
      case DeviceType.DESKTOP:
        return 'MonitorIcon'
      case DeviceType.TABLET:
        return 'TabletIcon'
      case DeviceType.MOBILE:
        return 'SmartphoneIcon'
      default:
        return null
    }
  }

  isKnown(name: string): boolean {
    return name != 'UNK'
  }

  get deviceName() {
    const { deviceName, osFullName, clientName } = this.device

    return this.isKnown(deviceName)
      ? deviceName : this.isKnown(clientName)
      ? clientName : osFullName
  }

  async disableSession() {
    try {
      this.device.isCurrent
        ? await this.logout()
        : await Auth.disconnectSession(this.device.id)

      this.$root.$emit('refreshSessions')
    } catch(e) {
      console.error('Error when trying to disable a session', e)
    }
  }
}
